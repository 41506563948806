@if (data) {
  <div class="column gap center text-align-center">
    @if (data.icon) {
      <ion-icon [src]="data.icon"></ion-icon>
    }
    @if (data.title) {
      <h3 [ngClass]="data.titleClass ? data.titleClass : ''">{{ data.title | translate }}</h3>
    }
    @if (data.text && data.innerHtml) {
      <span
        [ngClass]="data.textClass ? data.textClass : ''"
        class="no-margin text-md"
        [innerHTML]="data.text | translate"
      ></span>
    }
    @if (data.text && !data.innerHtml) {
      <p [ngClass]="data.textClass ? data.textClass : ''" class="text-md">
        {{ data.text | translate }}
      </p>
    }
    <div [ngClass]="data.buttonsClass ? data.buttonsClass : 'column gap center text-align-center'">
      @if (data.action && data.actionButtonText) {
        <button
          (click)="doActionAndClose()"
          mat-button
          appLoadingButton
          [ngClass]="'btn ' + data.actionButtonClass ? data.actionButtonClass : 'btn--primary'"
        >
          {{ data.actionButtonText | translate }}
          @if (data.actionButtonIcon) {
            <ion-icon class="action-icon" [name]="data.actionButtonIcon"></ion-icon>
          }
        </button>
      }
      <a class="text-btn grey-color-500 bold" (click)="close()">
        @if (data.dismissIcon) {
          <ion-icon class="action-icon" [name]="data.dismissIcon"></ion-icon>
        }
        {{ (data.dismissText ? data.dismissText : 'global.close') | translate }}</a
      >
    </div>
  </div>
}
