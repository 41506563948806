import { NgClass } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { IonIcon } from 'src/directives/icon';
import { LoadingStore } from 'src/stores/loading.store';

import { DialogInterface } from '../../../definitions/dialog.interface';
import { LoadingButtonDirective } from '../../../directives/loading-button.directive';

@Component({
  selector: 'app-general-dialog',
  templateUrl: './general-dialog.component.html',
  styleUrls: ['./general-dialog.component.scss'],
  standalone: true,
  imports: [MatButtonModule, TranslateModule, IonIcon, NgClass, LoadingButtonDirective]
})
export class GeneralDialogComponent {
  public data: DialogInterface = inject(MAT_DIALOG_DATA);
  private loadingStore = inject(LoadingStore);
  public dialogRef = inject(MatDialogRef<GeneralDialogComponent>);

  async close(): Promise<void> {
    if (this.data.dismissAction) {
      await this.data.dismissAction();
    }
    this.dialogRef.close();
  }

  /**
   * Execute the function passed as an argument and close the modal
   */
  async doActionAndClose(): Promise<void> {
    if (this.data.action) {
      await this.data.action();
      this.close();
    }
  }
}
